<template>
  <div class="content">
    <div class="content__title content__title--party content__title--start">
      <router-link
        :to="{ name: 'passport-immovables' }"
        class="button button--light button--no-indent button--small"
      >
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.70917 13.7082C7.61629 13.8013 7.50595 13.8752 7.38447 13.9256C7.263 13.976 7.13277 14.002 7.00125 14.002C6.86973 14.002 6.7395 13.976 6.61802 13.9256C6.49655 13.8752 6.38621 13.8013 6.29332 13.7082L0.293962 7.70884C0.200846 7.61595 0.126968 7.50562 0.0765611 7.38414C0.0261537 7.26266 0.000206594 7.13243 0.000206606 7.00091C0.000206617 6.86939 0.0261537 6.73916 0.0765611 6.61769C0.126969 6.49621 0.200846 6.38587 0.293962 6.29299L6.29333 0.293626C6.48108 0.105873 6.73573 0.00039471 7.00125 0.000394734C7.26677 0.000394757 7.52142 0.105873 7.70918 0.293626C7.89693 0.481379 8.00241 0.736028 8.00241 1.00155C8.00241 1.26707 7.89693 1.52172 7.70917 1.70948L2.41574 7.00091L7.70917 12.2923C7.80229 12.3852 7.87617 12.4956 7.92658 12.617C7.97698 12.7385 8.00293 12.8688 8.00293 13.0003C8.00293 13.1318 7.97698 13.262 7.92658 13.3835C7.87617 13.505 7.80229 13.6153 7.70917 13.7082Z" fill="#2B93E7"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M16 7.00108C16 7.26627 15.8947 7.5206 15.7071 7.70811C15.5196 7.89563 15.2653 8.00098 15.0001 8.00098L2.00149 8.00098C1.7363 8.00098 1.48197 7.89563 1.29445 7.70811C1.10694 7.5206 1.00159 7.26627 1.00159 7.00108C1.00159 6.73589 1.10694 6.48156 1.29445 6.29405C1.48197 6.10653 1.7363 6.00119 2.00149 6.00119L15.0001 6.00119C15.2653 6.00119 15.5196 6.10653 15.7071 6.29405C15.8947 6.48157 16 6.73589 16 7.00108Z" fill="#2B93E7"/>
        </svg>
      </router-link>
      <h1 class="title title--big title--theme">Статистика: Сведения о недвижимом имуществе, находящимся в собственности региональной организации РСМ</h1>
    </div>
    <v-client-table
      :data="table.users"
      :columns="table.columns"
      :options="table.options"
      ref="userListTable"
      class="table-default"
    >
      <div slot="region_name" slot-scope="props" class="table-default__left">
        {{ props.row.region_name }}
      </div>
    </v-client-table>
  </div>
</template>

<script>

export default {
  name: 'StatHead',
  data() {
    return {
      table: {
        users: [],
        columns: ['region_name', 'accepted', 'draft', 'rejected', 'review', 'count'],
        options: {
          headings: {
            region_name: 'Регион',
            accepted: 'Приняты',
            draft: 'Черновики',
            rejected: 'Отклонены',
            review: 'На рассмотрении',
            count: 'Количество',
          },
          pagination: { show: false },
          sortable: [],
          perPage: 150,
          texts: {
            filter: '',
            filterPlaceholder: 'Поиск по таблице',
            noResults: 'Нет подходящих записей',
            filterBy: '',
            loading: 'Загрузка',
            count: '',
            limit: 'Количество записей на странице:',
            page: 'Страница:'
          },
          rowClassCallback: row => `table-default__row table-default__row--${row.status}`,
        },
      },
    }
  },
  mounted() {
    this.fetchItems()
  },
  methods: {
    fetchItems() {
      this.$store.dispatch('passport/GET_IMMOVABLES_STAT',)
        .then(response => {
          this.table.users = response.data
        })
        .catch(error => {
          this.$notify({
            type: 'warn',
            title: 'Внимание!',
            text: `Ошибка: ${ error.message }`
          })
        })
    },
  }
}
</script>
